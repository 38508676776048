import { DateTime } from 'luxon';
import { UserStatus } from './user-status.model';

export interface ClientDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  otherPhone: string;
  status: UserStatus;
  lastLogin?: string;
  activatable: boolean;
}

export class Client {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  otherPhone: string;
  status: UserStatus;
  lastLogin: Date | null;
  activatable: boolean;

  constructor(dto: ClientDto) {
    this.id = dto.id;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.email = dto.email;
    this.mobilePhone = dto.mobilePhone;
    this.otherPhone = dto.otherPhone;
    this.status = dto.status;
    this.lastLogin = dto.lastLogin ? DateTime.fromISO(dto.lastLogin).toJSDate() : null;
    this.activatable = dto.activatable;
  }
}
