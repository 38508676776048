import { Agency, AgencyDto } from '../../client/agency/agency.model';
import { Location, LocationDto } from '../../client/location/location.model';
import { Address, AddressDto } from './address-model';
import { ContractStatus } from './contract-status.model';
import { DateTime, Duration } from 'luxon';

export interface BaseContractSummaryDto {
  readonly id: number;
  readonly startTime?: string;
  readonly endTime?: string;
  readonly durationMinutes: number;
  readonly language: string;
  readonly status: ContractStatus;
  readonly address?: AddressDto;
  readonly location?: LocationDto;
  readonly agency: AgencyDto | null;
  readonly description?: string;
  readonly markedAsCancelled?: boolean;
  readonly createDate?: string;
}

export abstract class BaseContractSummary {
  id: number;
  startTime: Date | null;
  endTime: Date | null;
  durationMinutes: number;
  language: string;
  status: ContractStatus;
  address: Address | null;
  location: Location | null;
  agency: Agency | null;
  description: string | null;
  markedAsCancelled: boolean;
  createDate: Date | null;

  constructor(dto: BaseContractSummaryDto) {
    this.id = dto.id;
    this.startTime = dto.startTime ? DateTime.fromISO(dto.startTime).toJSDate() : null;
    this.endTime = dto.endTime ? DateTime.fromISO(dto.endTime).toJSDate() : null;
    this.durationMinutes = dto.durationMinutes;
    this.language = dto.language;
    this.status = dto.status;
    this.address = dto.address ? new Address(dto.address) : null;
    this.location = dto.location ? new Location(dto.location) : null;
    this.agency = dto.agency ? new Agency(dto.agency) : null;
    this.description = dto.description || null;
    this.markedAsCancelled = dto.markedAsCancelled || false;
    this.createDate = dto.createDate ? DateTime.fromISO(dto.createDate).toJSDate() : null;
  }

  get formattedDate(): string {
    if (!this.startTime) {
      return '-';
    }
    return DateTime.fromJSDate(this.startTime).toFormat('dd.MM.yyyy');
  }

  get formattedCreationTimestamp(): string {
    if (!this.createDate) {
      return '-';
    }
    return DateTime.fromJSDate(this.createDate).toFormat('dd.MM.yyyy, HH:mm');
  }

  get formattedStartAndEndTime(): string {
    if (!this.startTime) {
      return '-';
    }
    const startTime = DateTime.fromJSDate(this.startTime).toFormat('HH:mm');
    if (this.endTime) {
      const endTimeString = DateTime.fromJSDate(this.endTime).toFormat('HH:mm');
      return `${startTime} - ${endTimeString} Uhr`;
    }
    return `${startTime} Uhr`;
  }

  getFormattedStartAndEndTime(startTime?: Date): string {
    if (!startTime) {
      return '-';
    }
    const startTimeString = DateTime.fromJSDate(startTime).toFormat('HH:mm');
    if (this.durationMinutes) {
      const endTimeString = DateTime.fromJSDate(startTime)
        .plus(Duration.fromMillis(this.durationMinutes * 60 * 1000))
        .toFormat('HH:mm');
      return `${startTimeString} - ${endTimeString} Uhr`;
    }
    return `${startTimeString} Uhr`;
  }

  get computedAddress(): Address | null {
    if (this.location) {
      return this.location;
    }
    return this.address;
  }

  get addressSummary(): string {
    if (!this.computedAddress) {
      return '-';
    }
    let lines = [];
    lines.push(`${this.computedAddress.street}`);
    lines.push(`${this.computedAddress.zip} ${this.computedAddress.location}`);
    return lines.join(', ');
  }

  get addressSummaryWithName(): string {
    if (!this.computedAddress) {
      return '-';
    }
    let lines = [];
    if (this.computedAddress.name) {
      lines.push(this.computedAddress.name);
    }
    lines.push(`${this.computedAddress.street}`);
    lines.push(`${this.computedAddress.zip} ${this.computedAddress.location}`);
    return lines.join(', ');
  }

  get summary(): string {
    return `${this.language}, ${this.computedAddress?.location}\n${this.formattedDate}, ${
      this.formattedStartAndEndTime
    }
    ${
      this.startTime && DateTime.fromJSDate(this.startTime).hasSame(DateTime.now(), 'day')
        ? ' (heute)'
        : ''
    }`;
  }

  // has not yet started
  get inFuture(): boolean {
    return DateTime.now() < DateTime.fromJSDate(this.startTime!);
  }

  // has finished
  get inPast(): boolean {
    return DateTime.now() > DateTime.fromJSDate(this.endTime!);
  }

  get isOngoing(): boolean {
    const startTime = DateTime.fromJSDate(this.startTime!);
    const endTime = DateTime.fromJSDate(this.startTime!).plus(
      Duration.fromMillis(1000 * 3600 * this.durationMinutes)
    );
    const now = DateTime.now();
    return startTime < now && now < endTime;
  }
}
